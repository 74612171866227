import { requests } from "@/utils/requests";

export const getTop5CustomerOpen = async (
  startdate,
  enddate,
  L1Only,
  L2Only,
  L1AndL2,
  ISKOn,
  isClosed,
  isTechnical,
  isFulfillment,
  isAdministrative
) => {
  let query = "?";
  if (startdate != null) {
    query += "&startdate=" + startdate;
  }
  if (enddate != null) {
    query += "&enddate=" + enddate;
  }
  if (L1Only != null) {
    query += "&L1Only=" + L1Only;
  }
  if (L2Only != null) {
    query += "&L2Only=" + L2Only;
  }
  if (L1AndL2 != null) {
    query += "&L1AndL2=" + L1AndL2;
  }
  if (ISKOn != null) {
    query += "&ISKOn=" + ISKOn;
  }
  if (isClosed != null) {
    query += "&isClosed=" + isClosed;
  }
  if (isTechnical != null) {
    query += "&isTechnical=" + isTechnical;
  }
  if (isFulfillment != null) {
    query += "&isFulfillment=" + isFulfillment;
  }
  if (isAdministrative != null) {
    query += "&isAdministrative=" + isAdministrative;
  }
  const data = await requests({
    url: `/api/admin/chart/top5/by/CustomerOpen${query}`,
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};

export const getTop5PartnerOpen = async (
  startdate,
  enddate,
  L1Only,
  L2Only,
  L1AndL2,
  ISKOn,
  isClosed,
  isTechnical,
  isFulfillment,
  isAdministrative
) => {
  let query = "?";
  if (startdate != null) {
    query += "&startdate=" + startdate;
  }
  if (enddate != null) {
    query += "&enddate=" + enddate;
  }
  if (L1Only != null) {
    query += "&L1Only=" + L1Only;
  }
  if (L2Only != null) {
    query += "&L2Only=" + L2Only;
  }
  if (L1AndL2 != null) {
    query += "&L1AndL2=" + L1AndL2;
  }
  if (ISKOn != null) {
    query += "&ISKOn=" + ISKOn;
  }
  if (isClosed != null) {
    query += "&isClosed=" + isClosed;
  }
  if (isTechnical != null) {
    query += "&isTechnical=" + isTechnical;
  }
  if (isFulfillment != null) {
    query += "&isFulfillment=" + isFulfillment;
  }
  if (isAdministrative != null) {
    query += "&isAdministrative=" + isAdministrative;
  }
  const data = await requests({
    url: `/api/admin/chart/top5/by/PartnerOpen${query}`,
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};

export const getTop5ProductOpen = async (
  startdate,
  enddate,
  L1Only,
  L2Only,
  L1AndL2,
  ISKOn,
  isClosed,
  isTechnical,
  isFulfillment,
  isAdministrative
) => {
  let query = "?";
  if (startdate != null) {
    query += "&startdate=" + startdate;
  }
  if (enddate != null) {
    query += "&enddate=" + enddate;
  }
  if (L1Only != null) {
    query += "&L1Only=" + L1Only;
  }
  if (L2Only != null) {
    query += "&L2Only=" + L2Only;
  }
  if (L1AndL2 != null) {
    query += "&L1AndL2=" + L1AndL2;
  }
  if (ISKOn != null) {
    query += "&ISKOn=" + ISKOn;
  }
  if (isClosed != null) {
    query += "&isClosed=" + isClosed;
  }
  if (isTechnical != null) {
    query += "&isTechnical=" + isTechnical;
  }
  if (isFulfillment != null) {
    query += "&isFulfillment=" + isFulfillment;
  }
  if (isAdministrative != null) {
    query += "&isAdministrative=" + isAdministrative;
  }
  const data = await requests({
    url: `/api/admin/chart/top5/by/ProductOpen${query}`,
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};
export default {
  getTop5CustomerOpen,
  getTop5PartnerOpen,
  getTop5ProductOpen,
};
