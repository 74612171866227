<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>관리자 - 통계</h1>
      </v-col>
      <v-col cols="auto" class="ml-auto">
        <v-btn color="#FF4D00" dark block @click="$router.push('/admin')">
          BACK
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="py-2">
            <h4>Filter</h4>
          </v-card-title>
          <v-card-text class="pa-0">
            <v-container fluid>
              <v-row>
                <v-col class="">
                  <v-text-field
                    type="date"
                    dense
                    hide-details
                    v-model="startdate"
                  ></v-text-field>
                </v-col>
                <v-col cols="auto">~</v-col>
                <v-col class="">
                  <v-text-field
                    type="date"
                    dense
                    hide-details
                    v-model="enddate"
                  ></v-text-field>
                </v-col>
                <v-col class="" cols="auto">
                  <v-switch
                    class="pa-0 ma-0 d-inline"
                    hide-details="auto"
                    label="L1 Only"
                    v-model="L1_Only"
                    @change="changeFilterSW_L1Only"
                  ></v-switch>
                  <v-switch
                    class="pa-0 ma-0"
                    hide-details="auto"
                    label="L2 Only"
                    v-model="L2_Only"
                    @change="changeFilterSW_L2Only"
                  ></v-switch>
                  <v-switch
                    class="pa-0 ma-0"
                    hide-details="auto"
                    label="L1+L2(All)"
                    v-model="L1_L2"
                    @change="changeFilterSW_L1L2"
                  ></v-switch>
                </v-col>
                <v-col class="" cols="auto">
                  <v-switch
                    class="pa-0 ma-0 d-inline"
                    hide-details="auto"
                    label="ISK On"
                    v-model="ISK_On"
                    @change="changeFilterSW_ISKOn"
                  ></v-switch>
                </v-col>
                <v-col class="" cols="auto">
                  <v-switch
                    class="pa-0 ma-0 d-inline"
                    hide-details="auto"
                    :label="`${isClosed ? 'closeDttm' : 'openDttm'}`"
                    v-model="isClosed"
                  ></v-switch>
                </v-col>
                <v-col class="" cols="auto">
                  <v-switch
                    class="pa-0 ma-0 d-inline"
                    hide-details="auto"
                    label="isTechnical"
                    v-model="isTechnical"
                  ></v-switch>
                  <v-switch
                    class="pa-0 ma-0 d-inline"
                    hide-details="auto"
                    label="isFulfillment"
                    v-model="isFulfillment"
                  ></v-switch>
                  <v-switch
                    class="pa-0 ma-0 d-inline"
                    hide-details="auto"
                    label="isAdministrative"
                    v-model="isAdministrative"
                  ></v-switch>
                </v-col>
                <v-col>
                  <v-btn
                    block
                    color="#083272"
                    dark
                    @click="updateAll"
                    class="py-9"
                  >
                    <h3>조회</h3>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title>
            <h4 class="text-center">Top 5 Customers in All Cases</h4>
          </v-card-title>
          <v-card-text>
            <PieLegendChart title="" :series="top5CustomerOpen" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title>
            <h4 class="text-center">Top 5 Partners in All Cases</h4>
          </v-card-title>
          <v-card-text>
            <PieLegendChart title="" :series="top5PartnerOpen" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title>
            <h4 class="text-center">Top 5 Products in All Cases</h4>
          </v-card-title>
          <v-card-text>
            <PieLegendChart title="" :series="top5ProductOpen" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import PieLegendChart from "@/components/Charts/PieLegendChart";
import {
  getTop5CustomerOpen,
  getTop5PartnerOpen,
  getTop5ProductOpen,
} from "@/api/Admin/Charts";
export default {
  components: {
    PieLegendChart,
  },
  mounted() {
    this.updateAll();
  },
  methods: {
    async updateAll() {
      this.getTop5CustomerOpen();
      this.getTop5PartnerOpen();
      this.getTop5ProductOpen();
    },
    async getTop5CustomerOpen() {
      const data = await getTop5CustomerOpen(
        this.startdate,
        this.enddate,
        this.L1_Only ? 1 : 0,
        this.L2_Only ? 1 : 0,
        this.L1_L2 ? 1 : 0,
        this.ISK_On ? 1 : 0,
        this.isClosed ? 1 : 0,
        this.isTechnical ? 1 : 0,
        this.isFulfillment ? 1 : 0,
        this.isAdministrative ? 1 : 0
      );
      console.log(data);
      const series = {
        name: "Companies",
        colorByPoint: true,
        data: [],
      };
      if (data.success) {
        series.data = data.data.map((e) => ({
          name: e.company_name,
          y: e.CNT,
        }));
      }
      this.top5CustomerOpen = [series];
    },
    async getTop5PartnerOpen() {
      const data = await getTop5PartnerOpen(
        this.startdate,
        this.enddate,
        this.L1_Only ? 1 : 0,
        this.L2_Only ? 1 : 0,
        this.L1_L2 ? 1 : 0,
        this.ISK_On ? 1 : 0,
        this.isClosed ? 1 : 0,
        this.isTechnical ? 1 : 0,
        this.isFulfillment ? 1 : 0,
        this.isAdministrative ? 1 : 0
      );
      console.log(data);
      const series = {
        name: "Companies",
        colorByPoint: true,
        data: [],
      };
      if (data.success) {
        series.data = data.data.map((e) => ({
          name: e.company_name,
          y: e.CNT,
        }));
      }
      this.top5PartnerOpen = [series];
    },
    async getTop5ProductOpen() {
      const data = await getTop5ProductOpen(
        this.startdate,
        this.enddate,
        this.L1_Only ? 1 : 0,
        this.L2_Only ? 1 : 0,
        this.L1_L2 ? 1 : 0,
        this.ISK_On ? 1 : 0,
        this.isClosed ? 1 : 0,
        this.isTechnical ? 1 : 0,
        this.isFulfillment ? 1 : 0,
        this.isAdministrative ? 1 : 0
      );
      console.log(data);
      const series = {
        name: "Products",
        colorByPoint: true,
        data: [],
      };
      if (data.success) {
        series.data = data.data.map((e) => ({
          name: e.product_name,
          y: e.CNT,
        }));
      }
      this.top5ProductOpen = [series];
    },
    async changeFilterSW_L1Only(data) {
      if (data) {
        this.L2_Only = false;
        this.L1_L2 = false;
      } else {
        this.L2_Only = false;
        this.L1_L2 = true;
      }
    },
    async changeFilterSW_L2Only(data) {
      if (data) {
        this.L1_Only = false;
        this.L1_L2 = false;
      } else {
        this.L2_Only = false;
        this.L1_L2 = true;
      }
    },
    async changeFilterSW_L1L2(data) {
      if (data) {
        this.L1_Only = false;
        this.L2_Only = false;
      } else {
        this.L2_Only = false;
        this.L1_Only = true;
      }
    },
    async changeFilterSW_ISKOn(data) {
      data;
    },
  },
  data() {
    return {
      startdate: moment().subtract(1, "month").format("yyyy-MM-DD"),
      enddate: moment().format("yyyy-MM-DD"),
      top5CustomerOpen: null,
      top5PartnerOpen: null,
      top5ProductOpen: null,
      L1_Only: false,
      L2_Only: false,
      L1_L2: true,
      ISK_On: false,
      isClosed: true,
      isTechnical: true,
      isFulfillment: true,
      isAdministrative: true,
    };
  },
};
</script>
