<template>
  <highcharts :options="chartOptions"></highcharts>
</template>

<script>
// import highcharts from "highcharts";
// require("highcharts/highcharts-more.js")(highcharts);
export default {
  props: {
    title: {
      type: String,
      require: false,
      default: () => {
        return "";
      },
    },
    series: {
      type: Array,
      require: false,
      default: () => {
        return [];
      },
    },
  },
  watch: {
    title() {
      this.setTitle();
    },
    series() {
      this.setSeries();
    },
  },
  mounted() {
    this.setTitle();
    this.setSeries();
  },
  methods: {
    setTitle() {
      this.chartOptions.title.text = this.title;
    },
    setSeries() {
      if (this.series == null) return;
      if (this.series.length == 0) return;
      this.chartOptions.series = this.series;
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "",
        },
        tooltip: {
          pointFormat: "<b>{point.percentage:.1f} % / {point.y} Cases</b>",
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b>: {point.y} Cases",
            },
            showInLegend: true,
          },
        },
        series: [],
      },
    };
  },
};
</script>
